@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Nunito+Sans:opsz@6..12&family=Nunito:ital,wght@0,500;0,600;1,400;1,500&family=Open+Sans:ital,wght@1,500&family=Playfair+Display:ital@1&display=swap');
.game__nav{
    background-color: #ffff;
    position: relative;
}
.game__button,
.game__page__title{
    background-color:#7037ed;
    font-family: Balsamiq Sans, Verdana, Arial, sans-serif;
    font-weight: 600;
    font-size: 0.7em;
    border: 1px solid #642be1;
    border-radius: 5px;
    padding: 12px 24px;
    color: white;
}
.game__page__title{
    color:#7037ed;
    background: #ffff;
    border: none;
    border-radius: none;
    border-bottom: none;
}
.game:hover{
    cursor: pointer;
    transform: translateX(10);
    
}

@media screen and (min-width: 768px) {
    .game__button,.game__page__title{
        font-size: 2em;
    }
    
}
@media screen and (min-width: 1024px) {
    .game__button,.game__page__title{
        font-size: 2.5em;
    }
    
}