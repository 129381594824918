.game__hero__container {
  padding: 0px 20px;
  font: 2em;
  color: #040742;
  min-height: 50vh;
}
.game__hero__heading__text {
  font-size: 2em;
  text-align: center;
}
.emphasized__text {
  color: #7037ed;
}
.game__hero__container {
  max-width: 1024px;
  padding: 20px 30px;
  display: flex;
  margin: auto;
  width: 100%;
  flex-direction: column;
}
.games__container {
  min-width: 50%;
}
.games__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: auto;
  margin-top: 20px;
}

.game {
  height: 200px;
  width: 200px;
  margin: auto;
  background: linear-gradient(#040742, #9198e5);
  border-radius: 5px;
  overflow: hidden;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .game__hero__container {
    flex-direction: row;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    transition: all 0.1s ease-in-out;
  }
  .game__hero__text {
    font-size: 0.5em;
  }
}
@media screen and (min-width: 1024px) {
  .game__hero__container {
    font-size: 2em;
  }
  .games__container {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 20px;
  }
  .game:nth-child(1),
  .game:nth-child(4) {
    transform: rotate(10deg);
  }
  .game:nth-child(2),
  .game:nth-child(3) {
    transform: rotate(-70deg);
  }
}
