@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Nunito+Sans:opsz@6..12&family=Nunito:ital,wght@0,500;0,600;1,400;1,500&family=Open+Sans:ital,wght@1,500&family=Playfair+Display:ital@1&display=swap');

.game__card{
    min-width: 200px;
    border: 1px solid #7037ed;
    border-radius:5px;
    max-width: 320px;
    overflow: hidden;
    font-family: Balsamiq Sans, Verdana, Arial, sans-serif;
    margin-top: 0.5em;
}
.game__card__image img {
    min-width: 100%;
    height: 300px;
}
.game__card__text{
    min-height: 200px;
    padding: 0 0.2em;
}
.game__card__text{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #040742;
    font-size: 1em;
}
.game__card__text--header{
    font-size: 2em;
}
.game__card__playbtn{
    background-color: #7037ed;
    color: white;
    text-align: center;
    font-size: 2em;
}
.game__card__playbtn:hover{
    cursor: pointer;
}